import { httpClient } from '../services/httpClient/httpClient';
import { GetDataById } from './models/common';
import { UserMetricsAPIModel } from './models/users';
import { getApiUrlForId, VideosEndpoints } from './endpoints';
import { VideosMetricsApiModel } from './models/videos';

export const getVideoMetrics = async (options: GetDataById) =>
  await httpClient.get<GetDataById, UserMetricsAPIModel>({
    url: getApiUrlForId(VideosEndpoints.GetVideoMetrics, options.id),
    requiresToken: true,
  });
export const getSVmetrics = async (options: { id: string }) =>
  await httpClient.get<{ id: string }, VideosMetricsApiModel>({
    url: getApiUrlForId(VideosEndpoints.GetSvMetrics, options.id),
    requiresToken: true,
  });
